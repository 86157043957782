import React, { useState, useEffect } from 'react';
import TutorialCard from '../TutorialCard/TutorialCard';
import styles from './TutorialCards.module.css';

function TutorialCards() {
    const [popularTutorials, setPopularTutorials] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        
        const fetchTutorials = async () => {
            try {
                setIsLoading(true);
                setError(null);
                
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/tutorials-reeffactory.json`);
                
                if (!response.ok) {
                    throw new Error('Failed to fetch tutorials');
                }
                
                const data = await response.json();
                
                if (isMounted) {
                    const popularTopicsWithCategories = [];
                    
                    data.categories.forEach(category => {
                        category.topics.forEach(topic => {
                            if (data.popular_tutorials.includes(topic.topic_link) && 
                                !data.hidden_tutorials_popular_page?.includes(topic.topic_link)) {
                                popularTopicsWithCategories.push({
                                    ...topic,
                                    categoryLink: category.category_link
                                });
                            }
                        });
                    });
                    
                    setPopularTutorials(popularTopicsWithCategories);
                }
            } catch (error) {
                console.error('Error loading tutorials:', error);
                if (isMounted) {
                    setError('Failed to load tutorials. Please try again later.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };
    
        fetchTutorials();
    
        return () => {
            isMounted = false;
        };
    }, []);

    if (isLoading) {
        return (
            <main className={styles.comoqMain}>
                <div className={styles.comoqLoadingContainer}>
                    <div className={styles.comoqSpinner}></div>
                </div>
            </main>
        );
    }

    if (error) {
        return (
            <main className={styles.comoqMain}>
                <div className={styles.comoqErrorContainer}>
                    <p className={styles.comoqErrorMessage}>{error}</p>
                    <button 
                        onClick={() => window.location.reload()}
                        className={styles.comoqRetryButton}
                    >
                        Spróbuj ponownie
                    </button>
                </div>
            </main>
        );
    }

    return (
        <main className={styles.comoqMain}>
            <header className={styles.comoqHeader}>
                <h1>Popularne instrukcje</h1>
                <p>Od zasad sprzedaży poprzez szczegółowy opis gwarancji i zwrotów. Znajdziesz tu najcześniej wyszukiwane tematy</p>
            </header>
            {popularTutorials.length > 0 ? (
                <section className={styles.comoqCardContainer}>
                    {popularTutorials.map(tutorial => (
                        <article
                            key={tutorial.topic_link}
                            className={styles.comoqCardWrapper}
                        >
                            <TutorialCard 
                                tutorial={tutorial} 
                                categoryLink={tutorial.categoryLink}
                            />
                        </article>
                    ))}
                </section>
            ) : (
                <p className={styles.comoqNoTutorials}>Nie znaleziono popularnych instrukcji.</p>
            )}
        </main>
    );
}

export default TutorialCards;