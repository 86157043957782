import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CategoryCard.module.css';

function CategoryCard({ category }) {
  const iconUrl = `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/${category.icon_path}`;

  return (
    <article className={styles.comoqCategoryCard} data-icon-id={category.icon_ID}>
      <div className={styles.comoqCategoryTileHeader}>
        {category.icon_path && (
          <img 
            className={styles.comoqCategoryIcon}
            alt={`Icon for ${category.category_name}`}
            src={iconUrl}
          />
        )}
        <h3>{category.category_name}</h3>
      </div>
      <ul className={styles.comoqCategoryList}>
        {category.topics.map((topic, index) => (
          <li key={index}>
            <Link to={`/device/${category.category_link}/${encodeURIComponent(topic.topic_link)}`}>
              {topic.topic_title}
            </Link>
          </li>
        ))}
      </ul>
    </article>
  );
}

export default CategoryCard;