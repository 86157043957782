import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

// Error handling for root mounting
const mountApp = () => {
  try {
    const container = document.getElementById('comoqClient');
    if (!container) {
      throw new Error('Failed to find the root element with id "comoqClient"');
    }

    const root = ReactDOM.createRoot(container);
    root.render(
      <React.StrictMode>
        <HashRouter>
          <App />
        </HashRouter>
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Error mounting the application:', error);
    // Optionally render a fallback error message
    document.body.innerHTML = '<div style="text-align: center; padding: 20px;">Error loading the application. Please try refreshing the page.</div>';
  }
};

// Execute the mounting
mountApp();

// Optional: Add error tracking for unhandled errors
window.addEventListener('unhandledrejection', event => {
  console.error('Unhandled promise rejection:', event.reason);
});

window.addEventListener('error', event => {
  console.error('Global error:', event.error);
});