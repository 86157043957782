import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChatBox from '../ChatBox/ChatBox';  // Add this import
import styles from './DevicesPage.module.css';

const DevicesPage = () => {
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/tutorials-reeffactory.json`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        
        const data = await response.json();
        
        if (isMounted && data.categories) {
          // Filter out hidden categories using the new configuration key
          const filteredDevices = data.categories.filter(
            device => !data.hidden_categories_devices_page?.includes(device.category_link)
          );
          setDevices(filteredDevices);
        }
      } catch (error) {
        console.error('Error loading devices:', error);
        if (isMounted) {
          setError('Failed to load devices. Please try again later.');
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>
          Spróbuj ponownie
        </button>
      </div>
    );
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.comoqWrapper}>
        <section className={styles.comoqSection}>
          <ChatBox />
        </section>
      </div>

      <div className={styles.headerContainer}>
        <h1>Urządzenia</h1>
        <p>Wszystkie urządzenia jakie posiadamy w swojej ofercie. Znajdziesz tutaj odpowiedzi na wszystkie swoje pytania.</p>
        <Link to="/" className={styles.backButton}>
          Powrót do kategorii
        </Link>
      </div>

      <div className={styles.devicesGrid}>
        {devices.map((device) => (
          <Link
            key={device.category_link}
            to={`/device/${device.category_link}/${device.topics[0]?.topic_link || device.category_link}`}
            className={styles.deviceCard}
          >
            <div className={styles.imageContainer}>
              {device.icon_path && (
                <img
                  src={`${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/${device.icon_path}`}
                  alt={`Icon for ${device.category_name}`}
                  className={styles.deviceIcon}
                />
              )}
            </div>
            <h2>{device.category_name}</h2>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DevicesPage;