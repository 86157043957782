import React, { useState, useEffect } from 'react';
import styles from './ChatBox.module.css';
import { IoSend } from 'react-icons/io5'; // Import send icon from react-icons

function generateRandomID() {
    return Math.random().toString(36).substr(2, 9);
}

function Suggestions({ onSelectSuggestion }) {
    const suggestions = ["Jak połączyć moje urządzenie?", "Konfiguracja Routera Fritzbox", "Jak zaktualizować urządzenie"];

    return (
        <div className={styles.comoqSuggestionsContainer} role="list" aria-label="Suggestions">
            {suggestions.map((suggestion, index) => (
                <button key={index} onClick={() => onSelectSuggestion(suggestion)} className={styles.comoqSuggestionButton} role="listitem">
                    {suggestion}
                </button>
            ))}
        </div>
    );
}

function ChatBox() {
    const [userInput, setUserInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [inputActive, setInputActive] = useState(false);
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isTyping, setIsTyping] = useState(false);

    useEffect(() => {
        let isMounted = true;
    
        // Generate or retrieve the customerID
        const customerID = localStorage.getItem('customerID') || generateRandomID();
        localStorage.setItem('customerID', customerID);
    
        // Include customerID in the fetch request
        fetch(`${process.env.REACT_APP_API_BASE_URL}/comoqapp/start?customerID=${customerID}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Failed to initialize chat: ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => {
                if (isMounted) {
                    setThreadId(data.thread_id);
                    console.log("Chat initialized with thread ID:", data.thread_id);
                }
            })
            .catch(error => console.error('Error initializing chat:', error));
    
        return () => {
            isMounted = false;
        };
    }, []);    

    useEffect(() => {
        const messageArea = document.querySelector(`.${styles.comoqMessageArea}`);
        if (messageArea) {
            messageArea.scrollTop = messageArea.scrollHeight;
        }
    }, [messages]);

    const sendMessage = (message = userInput) => {
        if (!message.trim() || !threadId) return;

        setIsTyping(true); // Start showing typing indicator
        const newMessage = { content: message, sender: 'user' };
        setMessages([...messages, newMessage]);

        fetch(`${process.env.REACT_APP_API_BASE_URL}/comoqapp/chat`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message, thread_id: threadId })
        })
        .then(response => response.json())
        .then(data => {
            setMessages(messages => [...messages, { content: data.response, sender: 'assistant' }]);
            setIsTyping(false); // Stop showing typing indicator after receiving response
        })
        .catch(error => {
            console.error('Error:', error);
            setMessages(messages => [...messages, { content: 'Error fetching response', sender: 'assistant' }]);
            setIsTyping(false); // Stop showing typing indicator if there is an error
        });

        setUserInput(''); // Clear the input field
    };

    const handleInputFocus = () => {
        if (!isChatVisible) {
            setIsChatVisible(true);
            if (messages.length === 0) { // Ensures the initial message is only set once
                setIsTyping(true); // Start showing typing indicator
                setTimeout(() => {
                    setMessages([{ content: "Cześć! Jestem Twoim asystentem. Napisz proszę swoimi słowami, jakiej pomocy potrzebujesz a pokieruję Cię bezpośrednio do rozwiązania Twojego problemu. Możesz prowadzić ze mną swobodną rozmowę tak jak z pracownikiem naszej przychodni.", sender: 'assistant' }]);
                    setIsTyping(false); // Stop showing typing indicator
                }, 3000); // Delay in milliseconds to simulate typing
            }
        }
    };

    const handleSelectSuggestion = (suggestion) => {
        if (!isChatVisible) {
            setIsChatVisible(true);
        }
        sendMessage(suggestion);
    };

    const handleNewChat = () => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/comoqapp/start`)
            .then(response => response.json())
            .then(data => {
                setThreadId(data.thread_id); // Set the new thread ID from start endpoint
                setMessages([{ content: "Cześć! Jestem Twoim asystentem. Napisz proszę swoimi słowami, jakiej pomocy potrzebujesz a pokieruję Cię bezpośrednio do rozwiązania Twojego problemu. Możesz prowadzić ze mną swobodną rozmowę tak jak z pracownikiem naszej przychodni.", sender: 'assistant' }]);
                setUserInput(''); // Clear the input field
                setIsChatVisible(true); // Ensure the chat box is visible
            })
            .catch(error => {
                console.error('Error reinitializing chat:', error);
                // Optionally handle errors more gracefully in the UI
            });
    };

    const handleCloseChat = () => {
        setIsChatVisible(false);
        console.log("Chat closed");
        // No need to reset messages or user input
    };

    return (
        <div className={isChatVisible ? `${styles.comoqChatContainer} ${styles.comoqChatContainerOpen}` : `${styles.comoqChatContainer} ${styles.comoqChatContainerClosed}`}>
            <header className={styles.comoqChatHeader} aria-label="Chat Header">
                <h1>Witamy w pomocy Reef Factory</h1>
                <p>Witamy w centrum wsparcia Reef Factory. Postaramy się zrobić wszystko, aby pomóc Ci jak najszybciej. Zanim otworzysz nowy ticket wsparcia, sprawdź artykuły w poszukiwaniu rozwiązania.</p>
            </header>
            {isChatVisible ? (
                <section className={styles.comoqChatBox} aria-label="Chat Box">
                    <div className={styles.controlButtons}>
                        <button onClick={handleNewChat} className={styles.newChatButton}>Nowy Chat</button>
                        <button onClick={handleCloseChat} className={styles.closeChatButton} aria-label="Close Chat">&times;</button>
                    </div>
                    <div className={styles.comoqMessageArea} role="log" aria-live="polite">
                        {messages.map((msg, index) => (
                            <div key={index} className={`${styles.comoqChatMessage} ${msg.sender === 'user' ? styles.comoqUserMessage : styles.comoqAssistantMessage}`}>
                                {msg.content}
                            </div>
                        ))}
                        {isTyping && (
                            <div className={styles.typingIndicator} aria-live="polite" aria-label="Assistant is typing">
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                            </div>
                        )}
                    </div>
                    <footer className={styles.comoqInputSection} aria-label="Input Section">
                        <div className={styles.comoqInputWrapper}>
                            <input
                                type="text"
                                placeholder="Wpisz wiadomość"
                                value={userInput}
                                onChange={e => setUserInput(e.target.value)}
                                onFocus={handleInputFocus}
                                onKeyPress={e => e.key === 'Enter' && sendMessage()}
                                className={styles.comoqUserInput}
                                aria-label="User message input"
                            />
                            <button onClick={sendMessage} className={styles.comoqSendIcon} aria-label="Send message">
                                <IoSend />
                            </button>
                        </div>
                        <Suggestions onSelectSuggestion={handleSelectSuggestion} />
                    </footer>
                </section>
            ) : (
                <footer className={styles.comoqInputSection} aria-label="Input Section">
                    <div className={styles.comoqInputWrapper}>
                        <input
                            type="text"
                            placeholder="Porozmawiaj z naszym asystentem..."
                            value={userInput}
                            onChange={e => setUserInput(e.target.value)}
                            onFocus={handleInputFocus}
                            onKeyPress={e => e.key === 'Enter' && sendMessage()}
                            className={styles.comoqUserInput}
                            aria-label="User message input"
                        />
                        <button onClick={sendMessage} className={styles.comoqSendIcon} aria-label="Send message">
                            <IoSend />
                        </button>
                    </div>
                    <Suggestions onSelectSuggestion={handleSelectSuggestion} />
                </footer>
            )}
        </div>
    );
}

export default ChatBox;
