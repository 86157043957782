import React, { useState } from 'react';
import styles from './FeedbackForm.module.css';

function FeedbackForm() {
    const [rating, setRating] = useState('');
    const [feedback, setFeedback] = useState('');
    const [thankYouMessageVisible, setThankYouMessageVisible] = useState(false);

    const handleRatingChange = (value) => {
        setRating(value);
        if (value >= 4) {
            setThankYouMessageVisible(true);
        } else {
            setThankYouMessageVisible(false);
        }
    };

    const submitFeedback = (e) => {
        e.preventDefault();
        console.log('Submitting feedback:', { rating, feedback });
        setThankYouMessageVisible(true);
        setRating('');
        setFeedback('');
    };

    const emojis = [
        `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/8148028_emoji_steaming_icon.svg`,
        `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/8148019_emoji_frowned_icon.svg`,
        `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/8148035_emoji_neutral_icon.svg`,
        `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/8148042_emoji_smilling_icon.svg`,
        `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/8148040_emoji_smilling_icon.svg`
    ];

    const labels = [
        "Nie mogłem znaleźć",
        "",
        "",
        "",
        "Było bardzo łatwo!"
    ];

    return (
        <section className={styles.comoqFeedbackContainer}>
            <div className={styles.comoqTutorialFeedback}>
                {!thankYouMessageVisible && (
                    <>
                        <h1 className={styles.comoqFeedbackTitle}>Daj znać co myślisz!</h1>
                        <p className={styles.comoqFeedbackSubtitle}>
                            Czy było łatwo znaleźć informacje, której szukałeś?
                        </p>
                    </>
                )}
                <form onSubmit={submitFeedback}>
                    {!thankYouMessageVisible && (
                        <>
                            <div className={styles.comoqCesRatings} role="radiogroup" aria-labelledby="feedback-label">
                                {emojis.map((emoji, index) => (
                                    <div key={index} className={styles.comoqEmojiContainer}>
                                        <input
                                            type="radio"
                                            id={`rating${index + 1}`}
                                            name="rating"
                                            value={index + 1}
                                            checked={rating === `${index + 1}`}
                                            onChange={(e) => handleRatingChange(parseInt(e.target.value))}
                                            className={styles.comoqRadioInput}
                                            aria-checked={rating === `${index + 1}`}
                                        />
                                        <label htmlFor={`rating${index + 1}`} className={styles.comoqLabel}>
                                            <img src={emoji} alt={`Rating ${index + 1}`} className={styles.comoqEmojiIcon} />
                                        </label>
                                        <span className={styles.comoqRatingLabel}>{labels[index]}</span>
                                    </div>
                                ))}
                            </div>
                            {rating >= 1 && rating <= 3 && (
                                <>
                                    <textarea
                                        className={styles.comoqFeedbackText}
                                        placeholder="Please let us know how we can improve."
                                        value={feedback}
                                        onChange={(e) => setFeedback(e.target.value)}
                                        aria-label="Feedback text area"
                                    ></textarea>
                                    <button type="submit" className={styles.comoqSubmitFeedback}>Submit Feedback</button>
                                </>
                            )}
                        </>
                    )}
                    {thankYouMessageVisible && (
                        <div className={styles.comoqThankYouMessage} role="alert">
                            Dziękujemy za Twoją opinię!
                        </div>
                    )}
                </form>
            </div>
        </section>
    );
}

export default FeedbackForm;
