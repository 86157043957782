import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ChatBox from '../ChatBox/ChatBox';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import styles from './TutorialPage.module.css';

function TutorialPage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isFirstVisit, setIsFirstVisit] = useState(true);
    const [tutorialData, setTutorialData] = useState(null);
    const [categories, setCategories] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [feedback, setFeedback] = useState('');
    const [rating, setRating] = useState(0);
    const [isGrayedOut, setIsGrayedOut] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [showTutorialList, setShowTutorialList] = useState(false);
    const [showCategories, setShowCategories] = useState(true);
    const [isCategorySelected, setIsCategorySelected] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
    const [allTutorialData, setAllTutorialData] = useState(null);
	const [openCategoryName, setOpenCategoryName] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const categoryContainerRef = useRef(null);

    const [currentImages, setCurrentImages] = useState({
        wrapperImage: '',
        stepImage: '',
        clickerImage: '',
        clickerPosition: { top: 0, left: 0 },
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { topicLink, deviceLink } = useParams();
    const navigate = useNavigate();
    const stepRefs = useRef([]); // Create a ref to store step elements

    // Mobile view handlers
    const handleCategorySelectionMobile = useCallback((categoryName) => {
        setSelectedCategory(categoryName);
        setShowTutorialList(true);
    }, []);

    const handleBackToCategories = () => {
        setShowTutorialList(false);
        setSelectedCategory(null);
        setTutorialData(null);
        setSelectedType(null);
    };

    // Add this new function to determine if the current step is a PC screen
    const isPcScreen = useCallback(() => {
        if (tutorialData && selectedType) {
            const currentStepData = tutorialData.options.find(option => option.type === selectedType)?.steps[currentStep];
            return currentStepData?.content_type === 'pc_screen';
        }
        return false;
    }, [tutorialData, selectedType, currentStep]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Fetch all data once on component mount
    useEffect(() => {
        const fetchAllData = async () => {
            if (allTutorialData) {
                // Use existing data if available
                const foundCategory = allTutorialData.categories.find(category => 
                    (deviceLink && category.category_link === deviceLink) || 
                    category.topics.some(topic => topic.topic_link === topicLink)
                );
                
                if (foundCategory) {
                    updateUIFromCategory(foundCategory);
                }
                return;
            }
    
            // Only fetch if we don't have data
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/tutorials-reeffactory.json`);
                if (!response.ok) throw new Error('Network response was not ok');
                
                const data = await response.json();
                setAllTutorialData(data);
                setCategories(data.categories);
                
                const foundCategory = data.categories.find(category => 
                    (deviceLink && category.category_link === deviceLink) || 
                    category.topics.some(topic => topic.topic_link === topicLink)
                );
    
                if (foundCategory) {
                    updateUIFromCategory(foundCategory);
                }
            } catch (error) {
                console.error("Error fetching tutorial data:", error);
                setError('Failed to load tutorial data.');
            } finally {
                setLoading(false);
            }
        };
    
        const updateUIFromCategory = (foundCategory) => {
            const foundTutorial = foundCategory.topics.find(topic => topic.topic_link === topicLink);
            if (foundTutorial) {
                setTutorialData(foundTutorial);
                setSelectedCategory(foundCategory.category_name);
                setIsGrayedOut(foundTutorial.options.length > 1);
                if (isFirstVisit && foundTutorial.options.length === 1) {
                    setSelectedType(foundTutorial.options[0].type);
                }
            }
        };
    
        fetchAllData();
    }, [deviceLink, topicLink, isFirstVisit, allTutorialData]); // Add allTutorialData to dependencies
    
    useEffect(() => {
        setLoading(true);
        setError(null);
        const tutorialsUrl = `${process.env.REACT_APP_API_BASE_URL}/data/tutorials-reeffactory.json`;
        fetch(tutorialsUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCategories(data.categories);
                const foundCategory = data.categories.find(category => 
                    (deviceLink && category.category_link === deviceLink) || 
                    category.topics.some(topic => topic.topic_link === topicLink)
                );
                if (foundCategory) {
                    const foundTutorial = foundCategory.topics.find(topic => topic.topic_link === topicLink);
                    if (foundTutorial) {
                        setTutorialData(foundTutorial);
                        setSelectedCategory(foundCategory.category_name);
                        setIsGrayedOut(foundTutorial.options.length > 1);
                        if (isFirstVisit && foundTutorial.options.length === 1) {
                            setSelectedType(foundTutorial.options[0].type);
                        }
                    }
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching tutorial data:", error);
                setError('Failed to load tutorial data.');
                setLoading(false);
            });
    }, [topicLink, deviceLink, isFirstVisit]);

    // Add this function inside your TutorialPage component
    const scrollCategories = (direction) => {
        const container = categoryContainerRef.current;
        if (!container) return;
        
        const scrollAmount = 300;
        const newPosition = direction === 'left' 
            ? Math.max(scrollPosition - scrollAmount, 0)
            : Math.min(scrollPosition + scrollAmount, container.scrollWidth - container.clientWidth);
        
        container.scrollTo({
            left: newPosition,
            behavior: 'smooth'
        });
        setScrollPosition(newPosition);
    };

    const updateImagesAndClicker = useCallback((stepDetails, topicLink) => {
        if (!stepDetails) {
            console.error("Step details missing for updateImagesAndClicker:", stepDetails);
            return; // Exit early if step details are undefined
        }
        setCurrentImages({
            wrapperImage: `${process.env.PUBLIC_URL}/tutorials/reeffactory/${topicLink}/phone-wrapper.png`,
            stepImage: stepDetails.image 
                ? `${process.env.PUBLIC_URL}/tutorials/reeffactory/${topicLink}/${stepDetails.image}` 
                : '', // Fallback to an empty string
            clickerImage: stepDetails.clicker 
                ? `${process.env.PUBLIC_URL}/tutorials/reeffactory/${topicLink}/clicker.png` 
                : '',
            clickerPosition: stepDetails.clicker || { top: 0, left: 0 },
        });
    }, []);
    
    const handleTypeSelection = useCallback((type) => {
        setSelectedType(type);
        setCurrentStep(0);
    
        const option = tutorialData.options.find(option => option.type === type);
        if (option && option.steps && option.steps.length > 0) {
            updateImagesAndClicker(option.steps[0], tutorialData.topic_link);
            setIsGrayedOut(false);
            setIsFirstVisit(false);
        } else {
            console.warn("No steps found for selected type:", type);
        }
    }, [tutorialData, updateImagesAndClicker]);    

    useEffect(() => {
        if (selectedType) {
            const option = tutorialData?.options.find(option => option.type === selectedType);
            if (option && option.steps && option.steps[currentStep]) {
                updateImagesAndClicker(option.steps[currentStep], tutorialData.topic_link);
            } else {
                console.warn("Invalid step data for selected type:", selectedType);
            }
        }
    }, [selectedType, currentStep, tutorialData, updateImagesAndClicker]);

    const handleCategorySelection = useCallback((categoryName) => {
        if (openCategoryName === categoryName) {
            setOpenCategoryName(null);
            setSelectedCategory(null);
            setIsCategorySelected(false);
        } else {
            setOpenCategoryName(categoryName);
            setSelectedCategory(categoryName);
            setIsCategorySelected(true);
            
            if (!isMobile) {
                const category = categories.find(cat => cat.category_name === categoryName);
                if (category && category.topics[0]) {
                    // Use replace instead of push
                    navigate(`/device/${category.category_link}/${category.topics[0].topic_link}`, {
                        replace: true
                    });
                }
            }
        }
    }, [categories, navigate, openCategoryName, isMobile]);

    const toggleCategories = () => {
        setShowCategories(prev => !prev); // Toggle visibility of category buttons
        setSelectedCategory(null); // Reset selected category
        setIsCategorySelected(false);  // Reset category selection state
    };

    const location = useLocation();
    const scrollPositionRef = useRef(0);

    // Modify selectTutorial to prevent page reload
    const selectTutorial = useCallback((selectedTopicLink) => {
        if (allTutorialData) {
            const selectedCategory = allTutorialData.categories.find(cat => 
                cat.topics.some(topic => topic.topic_link === selectedTopicLink)
            );
            const selectedTutorial = selectedCategory?.topics.find(t => t.topic_link === selectedTopicLink);
    
            if (!selectedTutorial || !selectedCategory) {
                console.warn("Category or tutorial not found for topic link:", selectedTopicLink);
                return; // Exit early if data is missing
            }
    
            setTutorialData(selectedTutorial);
            setSelectedCategory(selectedCategory.category_name);
            setIsGrayedOut(selectedTutorial.options.length > 1);
            setIsFirstVisit(true);
    
            if (selectedTutorial.options.length === 1) {
                setSelectedType(selectedTutorial.options[0].type);
            } else {
                setSelectedType(null);
            }
    
            navigate(`/device/${selectedCategory.category_link}/${selectedTopicLink}`, { replace: true });
            setShowCategories(false);
            setIsCategorySelected(false);
            setOpenCategoryName(null);
        }
    }, [allTutorialData, navigate]);    

    // Save scroll position before navigation
    useEffect(() => {
        const handleScroll = () => {
            scrollPositionRef.current = window.scrollY;
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Restore scroll position after state updates
    useEffect(() => {
        if (location.state?.scrollPosition) {
            window.scrollTo({
                top: location.state.scrollPosition,
                behavior: 'smooth'
            });
        }
    }, [location.state?.scrollPosition, tutorialData, selectedType]);

    const handlePrevStep = () => setCurrentStep(currentStep => Math.max(currentStep - 1, 0));
    const handleNextStep = () => setCurrentStep(currentStep => Math.min(currentStep + 1, tutorialData && selectedType ? tutorialData.options.find(option => option.type === selectedType).steps.length - 1 : 0));

    const handleSubmitFeedback = () => {
        console.log('Submitting feedback:', feedback, 'with rating:', rating);
        setFeedback('');
        setRating(0);
    };

    const handleBackToLanding = () => {
        navigate('/'); // Navigates to the home page
    };

    useEffect(() => {
        if (isFirstVisit && tutorialData && tutorialData.options.length === 1) {
            handleTypeSelection(tutorialData.options[0].type);
        }
    }, [isFirstVisit, tutorialData, handleTypeSelection]);

    useEffect(() => {
        if (selectedCategory) {
            const category = categories.find(cat => cat.category_name === selectedCategory);
            const shouldGrayOut = category && category.topics.some(t => t.topic_link === topicLink) && category.topics.find(t => t.topic_link === topicLink).options.length > 1;
            setIsGrayedOut(shouldGrayOut);
        }
    }, [selectedCategory, categories, topicLink]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const index = stepRefs.current.indexOf(entry.target);
                    if (index !== -1) {
                        setCurrentStep(index);
                    }
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.5 // Adjust this value as needed
        });

        stepRefs.current.forEach(step => {
            if (step) observer.observe(step);
        });

        return () => {
            stepRefs.current.forEach(step => {
                if (step) observer.unobserve(step);
            });
        };
    }, [tutorialData, selectedType]);

    const Breadcrumb = () => (
        <nav aria-label="breadcrumb">
            <div className={styles.breadcrumbContainer}>
                <div className={styles.comoqBreadcrumb}>
                    <span onClick={handleBackToLanding} className={styles.comoqBreadcrumbLink} role="button" tabIndex="0">Home</span>
                    {selectedCategory && <span> / <span className={styles.comoqBreadcrumbCategory}>{selectedCategory}</span></span>}
                    {tutorialData && <span> / <span className={styles.comoqBreadcrumbTopic}>{tutorialData.topic_title}</span></span>}
                </div>
            </div>
        </nav>
    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    if (isMobile) {
        return (
            <main className={styles.comoqPageContent}>
                <ChatBox />
                <Breadcrumb />
                
                <section className={styles.comoqContentWrapper}>
                    <h1 className={styles.sectionTitle}>Wybierz kategorie</h1>
                    <p className={styles.sectionSubtitle}>
                        Znajdź dowolny temat we wszystkich dostępnych kategoriach
                    </p>
                    
                    <div className={styles.categoryCarouselContainer}>
                        <div ref={categoryContainerRef} className={styles.categoryTilesContainer}>
                            {categories.map((category) => (
                                <button
                                    key={category.category_name}
                                    onClick={() => setSelectedCategory(category.category_name)}
                                    className={`${styles.categoryTile} ${
                                        selectedCategory === category.category_name ? styles.selectedCategoryTile : ""
                                    }`}
                                >
                                    {category.icon_path && (
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/${category.icon_path}`}
                                            alt={`Icon for ${category.category_name}`}
                                            className={styles.categoryIcon}
                                        />
                                    )}
                                    <span className={styles.categoryName}>{category.category_name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
    
                    {selectedCategory && (
                        <div className={styles.comoqTopicsContainer}>
                            <h2 className={styles.comoqTutorialListLabel}>Wybierz instrukcję:</h2>
                            <div className={styles.comoqTopicsMenu}>
                                <ul>
                                    {categories.find(cat => cat.category_name === selectedCategory)?.topics.map((topic) => (
                                        <li 
                                            key={topic.topic_link} 
                                            onClick={() => selectTutorial(topic.topic_link)}
                                            className={topic.topic_link === topicLink ? styles.comoqSelectedTutorial : ""}
                                            role="button"
                                            tabIndex="0"
                                        >
                                            {topic.topic_title}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </section>
    
                {tutorialData && selectedType && (
                    <section className={styles.comoqContainer}>
                        <h1 className={styles.comoqTitle}>{tutorialData.topic_title}</h1>
                        <p className={styles.comoqDescription}>{tutorialData.topic_description}</p>
                        <div className={styles.stepsContainer}>
                            {tutorialData?.options.find(option => option.type === selectedType)?.steps?.map((step, index) => (
                                <div 
                                    key={index} 
                                    ref={(el) => (stepRefs.current[index] = el)}
                                    className={`${styles.stepContainer} ${index === currentStep ? styles.activeStep : styles.inactiveStep}`}
                                    aria-current={index === currentStep ? 'step' : undefined}
                                >
                                    <div className={styles.stepInfoContainer}>
                                        <div className={styles.stepIndicator}>{index + 1}</div>
                                        <div dangerouslySetInnerHTML={{ __html: step.description || '' }} className={styles.stepDescription} />
                                    </div>
                                    {step.image && (
                                        <div className={styles.comoqPhoneWrapper}>
                                            <div 
                                                className={styles.comoqPhoneInner} 
                                                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/tutorials/reeffactory/${topicLink}/${step.image})` }}
                                            >
                                                {step.clicker && (
                                                    <button
                                                        className={styles.comoqClickerButton}
                                                        style={{
                                                            backgroundImage: `url(${process.env.PUBLIC_URL}/tutorials/reeffactory/${topicLink}/clicker.png)`,
                                                            top: `${step.clicker.top}%`,
                                                            left: `${step.clicker.left}%`
                                                        }}
                                                        aria-label={`Clicker for step ${index + 1}`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </section>
                )}
    
                <FeedbackForm onSubmit={handleSubmitFeedback} feedback={feedback} setFeedback={setFeedback} rating={rating} setRating={setRating} />
            </main>
        );
    } else {
        return (
            <main className={styles.comoqPageContent}>
                <ChatBox />
                <Breadcrumb />
                <section className={styles.comoqContentWrapper}>
                    <h1 className={styles.sectionTitle}>Wybierz kategorie</h1>
                    <p className={styles.sectionSubtitle}>
                        Znajdź dowolny temat we wszystkich dostępnych kategoriach
                    </p>
                    
                    <div className={styles.categoryCarouselContainer}>
                        <div ref={categoryContainerRef} className={styles.categoryTilesContainer}>
                            {categories.map((category) => (
                                <button
                                    key={category.category_name}
                                    onClick={() => handleCategorySelection(category.category_name)}
                                    className={`${styles.categoryTile} ${
                                        selectedCategory === category.category_name ? styles.selectedCategoryTile : ""
                                    }`}
                                >
                                    {category.icon_path && (
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/${category.icon_path}`}
                                            alt={`Icon for ${category.category_name}`}
                                            className={styles.categoryIcon}
                                        />
                                    )}
                                    <span className={styles.categoryName}>{category.category_name}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className={styles.scrollButtons}>
                        <button
                            onClick={() => scrollCategories('left')}
                            className={`${styles.scrollArrow} ${styles.scrollArrowLeft}`}
                            aria-label="Previous categories"
                        >
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/arrow-left.svg`} alt="Previous" />
                        </button>
                        
                        <button
                            onClick={() => scrollCategories('right')}
                            className={`${styles.scrollArrow} ${styles.scrollArrowRight}`}
                            aria-label="Next categories"
                        >
                            <img src={`${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/arrow-right.svg`} alt="Next" />
                        </button>
                    </div>

                    {tutorialData && (
                        <>
                            <h1 className={styles.comoqTitle}>{tutorialData.topic_title}</h1>
                            <p className={styles.comoqDescription}>{tutorialData.topic_description}</p>
                        </>
                    )}
                </section>

                <section className={`${styles.comoqContainer} ${isPcScreen() ? styles.pcScreenLayout : ''}`}>
                    <div className={styles.comoqRow}>
                        <div className={styles.comoqFirstColumn}>
                            <p className={styles.comoqTutorialListLabel}>Wybierz instrukcję:</p>
                            <div className={styles.comoqTopicsContainer}>
                                <div className={styles.comoqTopicsMenu}>
                                    {selectedCategory && (
                                        <ul>
                                            {categories.find(cat => cat.category_name === selectedCategory)?.topics.map((topic) => (
                                                <li 
                                                    key={topic.topic_link} 
                                                    onClick={() => selectTutorial(topic.topic_link)} 
                                                    className={topic.topic_link === topicLink ? styles.comoqSelectedTutorial : ""}
                                                    role="button" 
                                                    tabIndex="0"
                                                >
                                                    {topic.topic_title}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                            <button onClick={handleBackToLanding} className={styles.comoqBackButton}>Wróć do strony głównej</button>
                        </div>

                        <div className={`${styles.comoqContentColumns} ${isPcScreen() ? styles.pcScreenContentColumns : ''}`}>
                            {isFirstVisit && tutorialData && tutorialData.options.length > 1 ? (
                                <div className={styles.typeOverlay}>
                                    <div className={styles.typeSelection}>
                                        <h2>Select a Type:</h2>
                                        {tutorialData.options.map((option) => (
                                            <button
                                                key={option.type}
                                                onClick={() => handleTypeSelection(option.type)}
                                                className={selectedType === option.type ? styles.comoqSelectedCategory : ""}
                                            >
                                                {option.type}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className={styles.comoqSecondColumn}>
                                        <div className={styles.comoqPhoneWrapper} style={{ backgroundImage: `url(${currentImages.wrapperImage})` }}>
                                            <div className={styles.comoqPhoneInner} style={{ backgroundImage: `url(${currentImages.stepImage})` }}>
                                                {currentImages.clickerImage && (
                                                    <button
                                                        className={styles.comoqClickerButton}
                                                        style={{
                                                            backgroundImage: `url(${currentImages.clickerImage})`,
                                                            top: `${currentImages.clickerPosition.top}%`,
                                                            left: `${currentImages.clickerPosition.left}%`
                                                        }}
                                                        aria-label={`Clicker at ${currentImages.clickerPosition.top}%, ${currentImages.clickerPosition.left}%`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${styles.comoqThirdColumn} ${isPcScreen() ? styles.pcScreenThirdColumn : ''}`}>
                                        <div className={styles.stepsContainer}>
                                            {tutorialData?.options.find(option => option.type === selectedType)?.steps.map((step, index) => (
                                                <div 
                                                    key={index} 
                                                    className={`${styles.stepContainer} ${index === currentStep ? styles.activeStep : styles.inactiveStep}`}
                                                    aria-current={index === currentStep ? 'step' : undefined}
                                                    style={{ display: isPcScreen() && index !== currentStep ? 'none' : 'flex' }}
                                                >
                                                    <div className={styles.stepIndicator}>{index + 1}</div>
                                                    <div dangerouslySetInnerHTML={{ __html: step.description }} className={styles.stepDescription} />
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.comoqProgressContainer}>
                                            <div 
                                                className={styles.comoqProgressBar} 
                                                style={{
                                                    width: `${(currentStep + 1) / tutorialData?.options.find(option => option.type === selectedType)?.steps.length * 100}%`
                                                }}
                                            />
                                        </div>
                                        <div className={styles.comoqStepNavigation}>
                                            <button 
                                                className={styles.comoqStepNavigationPrev} 
                                                disabled={currentStep <= 0} 
                                                onClick={handlePrevStep}
                                            >
                                                Poprzedni
                                            </button>
                                            <button 
                                                className={styles.comoqStepNavigationNext} 
                                                disabled={currentStep >= (tutorialData?.options.find(option => option.type === selectedType)?.steps.length - 1)} 
                                                onClick={handleNextStep}
                                            >
                                                Następny
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </section>

                <FeedbackForm onSubmit={handleSubmitFeedback} feedback={feedback} setFeedback={setFeedback} rating={rating} setRating={setRating} />
            </main>
        );
    }
}

export default TutorialPage;
