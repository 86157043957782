import React, { useState, useEffect } from 'react';
import CategoryCard from '../CategoryCard/CategoryCard';
import styles from './CategoryCards.module.css';

function CategoryCards() {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let isMounted = true;
        
        const fetchCategories = async () => {
            try {
                setIsLoading(true);
                setError(null);
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/tutorials-reeffactory.json`);
                
                if (!response.ok) {
                    throw new Error('Failed to fetch categories');
                }
                
                const data = await response.json();
                
                if (isMounted) {
                    const validCategories = (data.categories || [])
                        .filter(category => 
                            category.category_link && 
                            category.topics && 
                            category.topics.length > 0 &&
                            !data.hidden_categories_landing_page?.includes(category.category_link)
                        );
                    setCategories(validCategories);
                }
            } catch (error) {
                console.error('Error loading categories:', error);
                if (isMounted) {
                    setError('Failed to load categories. Please try again later.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchCategories();

        return () => {
            isMounted = false;
        };
    }, []);

    if (isLoading) {
        return (
            <div className={styles.comoqLoadingContainer}>
                <div className={styles.comoqSpinner}></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.comoqErrorContainer}>
                <p className={styles.comoqErrorMessage}>{error}</p>
                <button 
                    onClick={() => window.location.reload()}
                    className={styles.comoqRetryButton}
                >
                    Spróbuj ponownie
                </button>
            </div>
        );
    }

    return (
        <div>
            <header className={styles.comoqCategoryHeader}>
                <h2>Wszystkie kategorie</h2>
                <p>Znajdź dowolny temat we wszystkich dostępnych kategoriach</p>
            </header>
            <div className={styles.comoqCategoryCardWrapper}>
                <div className={styles.comoqCategoryCardContainer}>
                    {categories.length > 0 ? (
                        categories.map(category => (
                            <CategoryCard key={category.id || category.category_link} category={category} />
                        ))
                    ) : (
                        <p className={styles.comoqNoCategories}>Nie znaleziono kategorii.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CategoryCards;