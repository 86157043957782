import React from 'react';
import ChatBox from '../ChatBox/ChatBox';
import PopularDevices from '../PopularDevices/PopularDevices';
import TutorialCards from '../TutorialCards/TutorialCards';
import CategoryCards from '../CategoryCards/CategoryCards';
import styles from './LandingPage.module.css';

function LandingPage() {
    return (
        <div id="landingPage" className={styles.comoqPageContent}>
            <div className={styles.comoqWrapper}>
                <section className={styles.comoqSection}>
                    <ChatBox />
                </section>
            </div>
            <div className={styles.comoqWrapper}>
                <section className={styles.comoqSection}>
                    <PopularDevices />
                </section>
            </div>
            <div className={styles.comoqWrapper}>
                <section className={styles.comoqSection}>
                    <TutorialCards />
                </section>
            </div>
            <div className={styles.comoqWrapper}>
                <section className={styles.comoqSection}>
                    <CategoryCards />
                </section>
            </div>
        </div>
    );
}

export default LandingPage;