import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PopularDeviceCard from '../PopularDeviceCard/PopularDeviceCard';
import styles from './PopularDevices.module.css';

function PopularDevices() {
    const [popularDevices, setPopularDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setError(null);
                
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/data/tutorials-reeffactory.json`);
                
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                
                const data = await response.json();
                
                if (isMounted && data.categories) {
                    // Filter and validate devices
                    const selectedDevices = data.categories
                        .filter(category => 
                            data.popular_devices.includes(category.category_link) &&
                            category.topics && 
                            category.topics.length > 0
                        );
                    setPopularDevices(selectedDevices);
                }
            } catch (error) {
                console.error('Error loading popular devices:', error);
                if (isMounted) {
                    setError('Failed to load popular devices. Please try again later.');
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
        
        return () => {
            isMounted = false;
        };
    }, []);

    const handleViewAllClick = () => {
        navigate('/devices');
    };

    if (isLoading) {
        return (
            <main className={styles.comoqMain}>
                <div className="flex justify-center items-center h-40">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            </main>
        );
    }

    if (error) {
        return (
            <main className={styles.comoqMain}>
                <div className="text-center p-4">
                    <p className="text-red-500">{error}</p>
                    <button 
                        onClick={() => window.location.reload()}
                        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Spróbuj ponownie
                    </button>
                </div>
            </main>
        );
    }

    return (
        <main className={styles.comoqMain}>
            <header className={styles.comoqHeader}>
                <h1>Popularne urządzenia</h1>
                <p>Wyszukaj w prosty sposób urządzenie, które posiadasz aby jeszcze szybciej uzyskać pomoc.</p>
            </header>
            
            {popularDevices.length > 0 ? (
                <div className={styles.comoqCardContainer}>
                    {popularDevices.map(device => (
                        <PopularDeviceCard 
                            key={device.category_link} 
                            category={device}
                        />
                    ))}
                </div>
            ) : (
                <p className="text-center p-4">Nie znaleziono popularnych urządzeń.</p>
            )}
            
            <div className={styles.comoqViewAllContainer}>
                <button 
                    className={styles.comoqViewAllButton}
                    onClick={handleViewAllClick}
                >
                    Zobacz wszystkie urządzenia
                </button>
            </div>
        </main>
    );
}

export default PopularDevices;