import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './TutorialCard.module.css';

function TutorialCard({ tutorial, categoryLink }) {
 const [iconPath, setIconPath] = useState('');
 const navigate = useNavigate();

 useEffect(() => {
   if (tutorial.icon_path) {
     setIconPath(`${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/${tutorial.icon_path}`);
   }
 }, [tutorial.icon_path]);

 const handleCardClick = () => {
   navigate(`/device/${categoryLink}/${encodeURIComponent(tutorial.topic_link)}`);
 };

 return (
   <div
     className={styles.comoqTutorialCard}
     onClick={handleCardClick}
     role="button"
     tabIndex="0"
     onKeyDown={(e) => e.key === 'Enter' && handleCardClick()}
   >
     {iconPath && (
       <img
         className={styles.comoqTutorialIcon}
         alt={`Icon for ${tutorial.topic_title}`}
         src={iconPath}
       />
     )}
     <div className={styles.comoqTutorialCardText}>
       <h2 className={styles.comoqTutorialTitleText}>{tutorial.topic_title}</h2>
     </div>
   </div>
 );
}

export default TutorialCard;