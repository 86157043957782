import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PopularDeviceCard.module.css';

function PopularDeviceCard({ category }) {
 const navigate = useNavigate();
 const iconUrl = `${process.env.REACT_APP_API_BASE_URL}/icons/reeffactory/${category.icon_path}`;

 const handleCardClick = () => {
   // Update to use the new URL structure
   navigate(`/device/${category.category_link}/${category.topics[0]?.topic_link}`);
 };

 return (
   <div
     className={styles.comoqDeviceCard}
     onClick={handleCardClick}
     role="button"
     tabIndex="0"
     onKeyDown={(e) => e.key === 'Enter' && handleCardClick()}
   >
     {category.icon_path && (
       <img
         className={styles.comoqDeviceIcon}
         alt={`Icon for ${category.category_name}`}
         src={iconUrl}
       />
     )}
     <div className={styles.comoqDeviceCardText}>
       <h2>{category.category_name}</h2>
     </div>
   </div>
 );
}

export default PopularDeviceCard;